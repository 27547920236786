body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.projects_allItems{
  text-align: center;
  margin-left: -8%;
}

.about{
  max-width: 1040px;
  margin-left: 23%;
}
h4{
  margin-top: 20%;
}

.navbar-brand{
  font-size: 32px;
  margin-left: -7%;
}
.nav-item{
  font-size: 24px;
}
.navbar{
  height: 4.5rem;
}

@media (min-width: 1200px){
  div.fluid-container{
    max-width: 1600px;
  }
  div.container{
    max-width: 1800px;
  }
  .about{
    max-width: 1040px;
    margin-left: 23%;
  }
}

div.fluid-container{
  margin-left: 7%;
}
div.container{
  margin-left: 7%;
}

h1{
  margin-top: 20px;
  margin-bottom: -55px;
  margin-left: 0.5rem;
  font-size: 72px;
}

.projects_searchBar{
  position: relative;
  right: 3rem;
  width: 300px;
  margin-top: 5rem;
  margin-left: 4%;
}
.projects_searchBar input{
  width: 100%;
}
.projects_searchBar .searchIcon{
  position: absolute;
  width: 2rem;
  right: 0.2rem;
  margin-top:7px;
}

@media only screen and (max-width: 1400px){
  .about{
    max-width: 1040px;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 800px){
  .projects_searchBar,
  .projects_searchBar form,
  .projects_searchBar input{
    width:95%;
    text-align:center;
  }
  .projects_searchBar .searchIcon{
    position: relative;
    margin-left: 90%;
    margin-bottom: 15%;
    margin-top:-5%;
  }

  div.fluid-container{
    margin-left: 0%;
    padding-left: 2%;
    padding-right: 2%;
  }
  div.container{
    margin-left: 0%;
  }
  .projects_allItems{
    margin-left: 10%;
  }
  h1{
    margin-left: 0%;
  }
  .home-h1{
    margin-left: 22%;
  }
  .about{
    max-width: 1040px;
    margin-left: 0%;
  }

  .navbar-brand{
    font-size: 24px;
    margin-left: 3%;
  }


  .about img{
    margin-left: auto;
  }
  .about h1{
    margin-left: auto;
  }
  .about h4{
    margin-left: auto;
    margin-top: 7%;
  }

  img{
    max-width: 100%;
  }

}